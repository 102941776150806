export const environment = {
  production: false,
  translate: {
    languages: ['es'],
    fileExtension: '.json',
  },
  api: {
    baseUrl: `https://${window.location.hostname}/api`,
    logosUrl: `https://${window.location.hostname}/resources/config/`,
    withCredentials: false
  }
};
