import {DatePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {TokenInterceptor} from '@core/_services/token-interceptor';
import {AuthGuard} from '@modules/shared/guard';
import {RoleGuard} from '@modules/shared/guard/role.guard';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToastrModule } from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@siemens/ngx-datatable';
import { TranslocoRootModule } from './transloco-root.module';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { QuillModule } from 'ngx-quill';
import { ConfigService } from './modules/shared/services/config.service';


export function initConfig(configService: ConfigService) {
  return () => configService.loadConfig();
}

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgIdleKeepaliveModule.forRoot(),
        ToastrModule.forRoot(),
        FormsModule,
        NgxDatatableModule,
        TranslocoRootModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        QuillModule.forRoot({
            customOptions: [{
              import: 'formats/font',
              whitelist: ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace']
            }]
          }),
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: [
      NgbActiveModal,
      Title,
      DatePipe,
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      AuthGuard,
      RoleGuard,
      ConfigService,
      {
        provide: APP_INITIALIZER,
        useFactory: initConfig,
        deps: [ConfigService],
        multi: true
      }]
})
export class AppModule { }
