import {Inject, Injectable} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public appConfig: any;
  private configSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public config$: Observable<any> = this.configSubject.asObservable();

  constructor(private apiService: ApiService) {
    //this.loadConfig();
  }

  loadConfig() {
      this.apiService.getClientConfig().subscribe(response => {
        console.log('llamada');
        this.configSubject.next(response.data);
        this.appConfig = response.data;
      });
  }
  getAppConfig() {
    return this.appConfig;
  }


}
